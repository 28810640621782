import { BASE_URL } from "../environment";
const baseUrl = BASE_URL;
const users = "users/";
const listings = "listings/";
const tags = "tags/";
const announcements = "announcements/";
const tickets = "tickets/";
const trades = "trades/";
const support = "support/";
export const api = {
  auth: {
    get loginUrl() {
      return `${baseUrl}${users}login`;
    },
    get signupUrl() {
      return `${baseUrl}${users}signup`;
    },
    get googleLoginUrl() {
      return `${baseUrl}${users}googleLogin`;
    },
    get googleSignupUrl() {
      return `${baseUrl}${users}googleSignup`;
    },
    get verifyEmailUrl() {
      return `${baseUrl}${users}verifyEmail`;
    },
    get resendLinkUrl() {
      return `${baseUrl}${users}resendLink`;
    },
    get isEmailTaken() {
      return `${baseUrl}${users}check/email`;
    },
    get isEmailTakenTradesperson() {
      return `${baseUrl}${users}check/email-tradesperson`;
    },
    get forgotPasswordUrl() {
      return `${baseUrl}${users}forgotPassword`;
    },
    get verifyResetPassUrl() {
      return `${baseUrl}${users}verifyResetPass`;
    },
    get resetPassUrl() {
      return `${baseUrl}${users}resetPass`;
    },
    getLandlordTenants(lisitngId) {
      return `${baseUrl}${users}myTenants/${lisitngId}`;
    }
  },
  dashboard: {
    // get landLordDashboard() {
    //   return `${baseUrl}landLordDashboard`;
    // },
    get tenantDashboard() {
      return `${baseUrl}tenantDashboard`;
    },
    get analytics() {
      return `${baseUrl}analytics`;
    },
    get updateActivityView() {
      return `${baseUrl}analytics/updateActivityView`;
    }
  },
  lisitng: {
    get addListingUrl() {
      return `${baseUrl}${listings}add`;
    },
    deleteListingUrl(listingId) {
      return `${baseUrl}${listings}delete/${listingId}`;
    },
    unassignListingUrl(listingId, tenantId) {
      return `${baseUrl}${listings}unAssign/${listingId}/${tenantId}`;
    },
    get getListingsUrl() {
      return `${baseUrl}${listings}all`;
    },
    get getTenantListingsUrl() {
      return `${baseUrl}${listings}assignedListing`;
    },
    getListingDetailsUrl(listingId) {
      return `${baseUrl}${listings}details/${listingId}`;
    },
    get assignTenantByLandlordUrl() {
      return `${baseUrl}${listings}assignLandlord`;
    },
    get assignPropertyByTenantUrl() {
      return `${baseUrl}${listings}assignTenant`;
    },
    get sendCreateAccountReminderUrl() {
      return `${baseUrl}${listings}create-account/reminders`;
    },
    get deleteTenantsFromProperty() {
      return `${baseUrl}${listings}delete-account/tenants`;
    },
    get updateTenancyPeriodUrl() {
      return `${baseUrl}${listings}update/tenancy`;
    },
    get updatePropertyRecord() {
      return `${baseUrl}${listings}update/property`;
    },
    get updatePropertyTagsRecord() {
      return `${baseUrl}${listings}update/property-tags`;
    },
    get deletePropertyBedroom() {
      return `${baseUrl}${listings}delete/property-bedroom`;
    },
    get addBedrooms() {
      return `${baseUrl}${listings}add/property-bedroom`;
    },
    get updateBedroomIdentifier() {
      return `${baseUrl}${listings}update/bedroom-identifier`;
    }
  },
  tags: {
    get getListings() {
      return `${baseUrl}${tags}add-tag-listings`;
    },
    get addTag() {
      return `${baseUrl}${tags}add-tag`;
    },
    get editTag() {
      return `${baseUrl}${tags}edit-tag`;
    },
    get deleteTag() {
      return `${baseUrl}${tags}delete-tag`;
    },
    get getTags() {
      return `${baseUrl}${tags}all-tags`;
    },
    get getTagsListing() {
      return `${baseUrl}${tags}all-tags-listing`;
    }
  },
  announcement: {
    get addAnnouncementUrl() {
      return `${baseUrl}${announcements}add`;
    },
    get getLandlordAnnouncementListUrl() {
      return `${baseUrl}${announcements}all`;
    },
    get getTenantAnnouncementListUrl() {
      return `${baseUrl}${announcements}tenantAll`;
    }
  },
  tickets: {
    get getAllTicketsCountUrl() {
      return `${baseUrl}${tickets}counter`;
    },
    get getAllTickets() {
      return `${baseUrl}${tickets}all`;
    },
    get getArchivedJobs() {
      return `${baseUrl}${tickets}archived/all`;
    },
    get addTicket() {
      return `${baseUrl}${tickets}add`;
    },
    get setTicketStatus() {
      return `${baseUrl}${tickets}maintanance`;
    },
    get uploadTicketImages() {
      return `${baseUrl}${tickets}add-images`;
    },
    get deleteTicketImages() {
      return `${baseUrl}${tickets}delete-images`;
    },
    get setTicketPrivate() {
      return `${baseUrl}${tickets}ticketPrivate`;
    },
    deleteMessage(id) {
      return `${baseUrl}${tickets}deleteChat/${id}`;
    },
    getTicketDetails(ticketId) {
      return `${baseUrl}${tickets}details/${ticketId}`;
    },
    // deleteTicket(ticketId) {
    //   return `${baseUrl}${tickets}delete/${ticketId}`;
    // },
    get assignTradesman() {
      return `${baseUrl}${tickets}assignTradesman`;
    },
    get bookJob() {
      return `${baseUrl}${tickets}book/job`;
    },
    get jobCompletion() {
      return `${baseUrl}${tickets}job-completion`;
    },
    get addComment() {
      return `${baseUrl}${tickets}addChat`;
    },
    get addPrivateComment() {
      return `${baseUrl}${tickets}addChatPrivate`;
    },
    get editComment() {
      return `${baseUrl}${tickets}editChat`;
    },
    get ExportCsv() {
      return `${baseUrl}${tickets}export-csv`;
    },
    get rateTicket() {
      return `${baseUrl}${tickets}feedback`;
    },
    rejectJob(rejectionId) {
      return `${baseUrl}${tickets}deleteAssignmentt/${rejectionId}`;
    },
    removeAssignedTradesman(assignmentId, ticketId, sendSMS) {
      return `${baseUrl}${tickets}deleteAssignment/${assignmentId}/${ticketId}/${sendSMS}`;
    },
    getComments(ticketId) {
      return `${baseUrl}${tickets}chatMessages/${ticketId}`;
    },
    getCommentsPrivate(ticketId) {
      return `${baseUrl}${tickets}chatMessagesPrivate/${ticketId}`;
    },
    assignedTradesmans(ticketId) {
      return `${baseUrl}${tickets}assignedTradesman/${ticketId}`;
    },
    closeTicket(ticketId, userId) {
      return `${baseUrl}${tickets}closeTicket/${ticketId}/${userId}`;
    },
    isClosed(ticketId) {
      return `${baseUrl}${tickets}isClosed/${ticketId}`;
    },
    deleteTicket(ticketId) {
      return `${baseUrl}${tickets}${ticketId}`;
    },
    extendDescription(ticketId) {
      return `${baseUrl}${tickets}extendDescription/${ticketId}`;
    },
    updateTicketDetails(ticketId) {
      return `${baseUrl}${tickets}${ticketId}`;
    },
    setPriority(ticketId) {
      return `${baseUrl}${tickets}setPriority/${ticketId}`;
    }
  },
  tradesman: {
    get addTradesman() {
      return `${baseUrl}${trades}add`;
    },
    get updateTradesman() {
      return `${baseUrl}${trades}update`;
    },
    get viewAllTradesman() {
      return `${baseUrl}${trades}all`;
    },
    get getLandlords() {
      return `${baseUrl}${trades}all-landlord`;
    },
    viewUnassignedTradesman(ticketId) {
      return `${baseUrl}${trades}assign/${ticketId}`;
    },
    checkTradesmanEmail(email) {
      return `${baseUrl}${trades}check/${email}`;
    },
    deleteTradesman(tradesmanId) {
      return `${baseUrl}${trades}delete/${tradesmanId}`;
    },
    getsingleTradesman(tradesmanId) {
      return `${baseUrl}${trades}/${tradesmanId}`;
    }
  },
  liaison: {
    get addAdminUser() {
      return `${baseUrl}liaison/addAdminUser`;
    },
    get viewAllLiaisons() {
      return `${baseUrl}liaison/all`;
    },
    getSingleLiaison(liaisonId) {
      return `${baseUrl}liaison/${liaisonId}`;
    },
    deleteLiaison(liaisonId) {
      return `${baseUrl}liaison/delete/${liaisonId}`;
    },
    updateLiaisonPassword(liaisonId) {
      return `${baseUrl}liaison/changePassword/${liaisonId}`;
    }
  },
  user: {
    get getLandlord() {
      return `${baseUrl}users/getLandlord`;
    },
    get checkInvitation() {
      return `${baseUrl}users/checkInvitation`;
    },
    get updateUserUrl() {
      return `${baseUrl}users/update/`;
    },
    get inviteLandlord() {
      return `${baseUrl}users/invite/landlord/`;
    },
    get unsubscribe() {
      return `${baseUrl}users/unsubscribe/invitation`;
    },
    get getAccountDetails() {
      return `${baseUrl}users/accountDetails`;
    },
    get getSubscriptionDetails() {
      return `${baseUrl}users/subscriptionDetails`;
    },
    get updateContactDetails() {
      return `${baseUrl}users/updateContactDetails`;
    },
    getUserDetailsByEmail(email) {
      return `${baseUrl}users/get-details/${email}`;
    },
    updateProfileImage(email) {
      return `${baseUrl}users/updateProfileImage/${email}`;
    },
    updateUserDetails(email) {
      return `${baseUrl}users/updateUserDetails/${email}`;
    }
  },
  messages: {
    get getMessages() {
      return `${baseUrl}messages/get-all`;
    },
    get getNotification() {
      return `${baseUrl}messages/get-all-notification`;
    },
    get markRead() {
      return `${baseUrl}messages/mark-read`;
    },
    get markReadNotifications() {
      return `${baseUrl}messages/explicit-read`;
    },
    get deleteNotifications() {
      return `${baseUrl}messages/notification/delete`;
    },
    markReadNotificationByOpen(id) {
      return `${baseUrl}messages/implicit-read/${id}`;
    },
    get markUnread() {
      return `${baseUrl}messages/mark-unread`;
    },
    get markReadTicket() {
      return `${baseUrl}messages/mark-read-ticket`;
    },
    get deleteMesages() {
      return `${baseUrl}messages/delete-messages`;
    },
    get getUnreadCount() {
      return `${baseUrl}messages/unread-count`;
    }
  },
  compliance: {
    get getCompliances() {
      return `${baseUrl}compliance/all`;
    },
    get addCompliance() {
      return `${baseUrl}compliance`;
    },
    getComplianceById(id) {
      return `${baseUrl}compliance/${id}`;
    },
    updateCompliance(id) {
      return `${baseUrl}compliance/${id}`;
    },
    deleteCompliance(id) {
      return `${baseUrl}compliance/${id}`;
    },
    archiveCompliance(id) {
      return `${baseUrl}compliance/archive/${id}`;
    },
    getAllCompliances() {
      return `${baseUrl}compliance/all/compliances`;
    },
    unArchiveCompliance() {
      return `${baseUrl}compliance/unarchive`;
    }
  },
  category: {
    getCategory(page, limit) {
      return `${baseUrl}category?page=${page}&limit=${limit}`;
    }
  },
  support: {
    get supportEmail() {
      return `${baseUrl}${support}`;
    }
  }
};
