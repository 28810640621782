const {
  DASHBOARD_ANALYTICS_REQUEST,
  DASHBOARD_ANALYTICS_SUCCESS,
  DASHBOARD_ANALYTICS_FAILURE,
  SET_MY_JOBS_FILTERS,
  SET_MY_JOBDATA,
  SET_INPROGRESS_CHART_CLICKED_DATA,
  SET_TICKET_TILE,
  FETCH_MY_JOBS_REQUEST,
  FETCH_MY_JOBS_SUCCESS,
  FETCH_MY_JOBS_FAIL,
  SET_AGEPROFILE_CHART_CLICKED_DATA,
  SET_MENUE,
  SET_ROW_ID,
  REMOVE_ROW_ID,
  SET_COMPLIANCE_TILE,
  SET_ACTIVE_PANEL,
  SET_ROW_SIZE,
  ARCHIVED_TICKET_FILTER_SET,
  FETCH_ARCHIVED_JOBS_FAIL,
  FETCH_ARCHIVED_JOBS_REQUEST,
  FETCH_ARCHIVED_JOBS_SUCCESS,
  UPDATE_ACTIVITY_VIEW_REQUEST,
  UPDATE_ACTIVITY_VIEW_SUCCESS,
  UPDATE_ACTIVITY_VIEW_FAIL
} = require("./types");

const initialState = {
  analytics: {},
  loading: false,
  feedLoading: false,
  filters: {
    status: "",
    property: "",
    tradesPerson: "",
    landlord: ""
  },
  tickets: [],
  archivedTickets: [],
  inProgressClickedData: null,
  ageProfileClickedData: null,
  ticketTile: null,
  currentMenue: JSON.parse(localStorage.getItem("current-menue")) || "/Dashboard",
  isDetail: false,
  complianceTile: null,
  ticketActivePanel: 0,
  viewTicketId: "",
  viewTicketsPageCount: 25,
  archivedTicketFilter: null
};
const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_ANALYTICS_REQUEST:
    case FETCH_MY_JOBS_REQUEST:
    case FETCH_ARCHIVED_JOBS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case UPDATE_ACTIVITY_VIEW_REQUEST:
      return {
        ...state,
        feedLoading: true
      };
    case UPDATE_ACTIVITY_VIEW_SUCCESS:
      return {
        ...state,
        feedLoading: false,
        analytics: { ...state.analytics, ...action.payload }
      };
    case SET_ACTIVE_PANEL:
      return {
        ...state,
        ticketActivePanel: action.payload
      };
    case DASHBOARD_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: action.payload,
        loading: false
      };
    case FETCH_MY_JOBS_SUCCESS:
      return {
        ...state,
        tickets: action.payload,
        loading: false
      };
    case FETCH_ARCHIVED_JOBS_SUCCESS:
      return {
        ...state,
        archivedTickets: action.payload,
        loading: false
      };
    case DASHBOARD_ANALYTICS_FAILURE:
    case FETCH_ARCHIVED_JOBS_FAIL:
    case FETCH_MY_JOBS_FAIL:
      return {
        ...state,
        loading: false
      };
    case UPDATE_ACTIVITY_VIEW_FAIL:
      return {
        ...state,
        feedLoading: false
      };
    case SET_MY_JOBS_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    case SET_MY_JOBDATA:
      return {
        ...state,
        tickets: action.payload
      };

    case SET_INPROGRESS_CHART_CLICKED_DATA:
      return {
        ...state,
        inProgressClickedData: action.payload
      };
    case SET_AGEPROFILE_CHART_CLICKED_DATA:
      return {
        ...state,
        ageProfileClickedData: action.payload
      };
    case SET_TICKET_TILE:
      return {
        ...state,
        ticketTile: action.payload
      };
    case SET_MENUE:
      localStorage.setItem("current-menue", JSON.stringify(action.payload.path));
      return {
        ...state,
        currentMenue: action.payload.path,
        isDetail: action.payload.isDetail ? action.payload.isDetail : false
      };
    case SET_ROW_ID:
      return {
        ...state,
        viewTicketId: action.payload
      };
    case SET_ROW_SIZE:
      return {
        ...state,
        viewTicketsPageCount: action.payload
      };
    case REMOVE_ROW_ID:
      return {
        ...state,
        viewTicketId: ""
      };
    case SET_COMPLIANCE_TILE:
      return {
        ...state,
        complianceTile: action.payload
      };
    case ARCHIVED_TICKET_FILTER_SET: {
      return {
        ...state,
        archivedTicketFilter: action.payload
      };
    }
    default:
      return state;
  }
};

export default analyticsReducer;
