export const DASHBOARD_ANALYTICS_REQUEST = "DASHBOARD_ANALYTICS_REQUEST";
export const DASHBOARD_ANALYTICS_SUCCESS = "DASHBOARD_ANALYTICS_SUCCESS";
export const DASHBOARD_ANALYTICS_FAILURE = "DASHBOARD_ANALYTICS_FAILURE";
export const SET_MY_JOBS_FILTERS = "SET_MY_JOBS_FILTERS";
export const SET_MY_JOBDATA = "SET_MY_JOBDATA";
export const SET_INPROGRESS_CHART_CLICKED_DATA = "SET_INPROGRESS_CHART_CLICKED_DATA";
export const SET_AGEPROFILE_CHART_CLICKED_DATA = "SET_AGEPROFILE_CHART_CLICKED_DATA";
export const SET_TICKET_TILE = "SET_TICKET_TILE";
export const FETCH_MY_JOBS_REQUEST = "FETCH_MY_JOBS_REQUEST";
export const FETCH_MY_JOBS_SUCCESS = "FETCH_MY_JOBS_SUCCESS";
export const FETCH_MY_JOBS_FAIL = "FETCH_MY_JOBS_FAIL";
export const SET_MENUE = "SET_MENUE";
export const SET_COMPLIANCE_TILE = "SET_COMPLIANCE_TILE";
export const SET_ACTIVE_PANEL = "SET_ACTIVE_PANEL";
export const SET_ROW_ID = "SET_ROW_ID";
export const REMOVE_ROW_ID = "REMOVE_ROW_ID";
export const SET_ROW_SIZE = "SET_ROW_SIZE";
export const ARCHIVED_TICKET_FILTER_SET = "ARCHIVED_TICKET_FILTER_SET";
export const FETCH_ARCHIVED_JOBS_REQUEST = "FETCH_ARCHIVED_JOBS_REQUEST";
export const FETCH_ARCHIVED_JOBS_SUCCESS = "FETCH_ARCHIVED_JOBS_SUCCESS";
export const FETCH_ARCHIVED_JOBS_FAIL = "FETCH_ARCHIVED_JOBS_FAIL";
export const UPDATE_ACTIVITY_VIEW_REQUEST = "UPDATE_ACTIVITY_VIEW_REQUEST";
export const UPDATE_ACTIVITY_VIEW_SUCCESS = "UPDATE_ACTIVITY_VIEW_SUCCESS";
export const UPDATE_ACTIVITY_VIEW_FAIL = "UPDATE_ACTIVITY_VIEW_FAIL";
