import { api } from "_endpoints/api";
import http from "../../axios";
import {
  DASHBOARD_ANALYTICS_REQUEST,
  DASHBOARD_ANALYTICS_FAILURE,
  DASHBOARD_ANALYTICS_SUCCESS,
  FETCH_MY_JOBS_REQUEST,
  FETCH_MY_JOBS_SUCCESS,
  FETCH_MY_JOBS_FAIL,
  FETCH_ARCHIVED_JOBS_REQUEST,
  FETCH_ARCHIVED_JOBS_SUCCESS,
  FETCH_ARCHIVED_JOBS_FAIL,
  UPDATE_ACTIVITY_VIEW_REQUEST,
  UPDATE_ACTIVITY_VIEW_SUCCESS,
  UPDATE_ACTIVITY_VIEW_FAIL
} from "./types";
import { setError } from "redux/errors/errorActions";

export const getAnalytics = () => {
  return async (dispatch, getState) => {
    try {
      const { filterTags } = getState().tag;
      dispatch({ type: DASHBOARD_ANALYTICS_REQUEST });
      const response = await http.post(api.dashboard.analytics, { filterTags: filterTags });
      dispatch({ type: DASHBOARD_ANALYTICS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: DASHBOARD_ANALYTICS_FAILURE, payload: error.response.data.message });
    }
  };
};

export const getJobs = () => {
  return async (dispatch, getState) => {
    try {
      const { filterTags } = getState().tag;
      dispatch({ type: FETCH_MY_JOBS_REQUEST });
      const response = await http.post(api.tickets.getAllTickets, { filterTags: filterTags });
      dispatch({ type: FETCH_MY_JOBS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_MY_JOBS_FAIL, payload: error.response?.data?.message });
    }
  };
};

export const getArchivedJobs = () => {
  return async (dispatch, getState) => {
    try {
      const { archivedTicketFilter } = getState().dashboard;
      const propertyIds = archivedTicketFilter.properties.map(({ value }) => value);
      const tradespersonIds = archivedTicketFilter.tradespersons.map(({ value }) => value);
      const ticketsFilter = {
        ...archivedTicketFilter,
        properties: propertyIds,
        tradespersons: tradespersonIds
      };
      dispatch({ type: FETCH_ARCHIVED_JOBS_REQUEST });
      const response = await http.post(api.tickets.getArchivedJobs, ticketsFilter);
      if (response.data.length === 0) {
        dispatch(setError([{ msg: "No records found", isOpen: true, duration: 6000, severity: "warning" }]));
      }
      dispatch({ type: FETCH_ARCHIVED_JOBS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_ARCHIVED_JOBS_FAIL, payload: error.response?.data?.message });
    }
  };
};

export const updateActivityView = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { filterTags } = getState().tag;
      dispatch({ type: UPDATE_ACTIVITY_VIEW_REQUEST });
      const response = await http.post(api.dashboard.updateActivityView, { filterTags, ...payload });
      dispatch({ type: UPDATE_ACTIVITY_VIEW_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: UPDATE_ACTIVITY_VIEW_FAIL, payload: error.response?.data?.message });
    }
  };
};
